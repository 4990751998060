<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col class="titleWidth">
        <version-status-title
          title="單位領域設定｜"
          :versionData="versionData"
        ></version-status-title>
      </v-col>
      <v-col v-if="!isFromSignOff()" class="d-flex align-center justify-end">
        <v-btn to="/audit-field/unit-field" depressed class="mr-2"
          >返回列表</v-btn
        >
        <v-btn
          v-if="
            versionData.status == 0 &&
            ((versionData.group == '分公司' &&
              rolesCheck('unitFieldByIdGroupStatus')) ||
              (versionData.group != '分公司' &&
                rolesCheck('unitFieldByIdStatus')))
          "
          @click="changeStatus(1)"
          depressed
          class="mr-2"
          color="primary"
          >製作初稿</v-btn
        >
        <v-btn
          v-if="versionData.status == 0 && rolesCheck('unitFieldByIdAddFactor')"
          @click="addFactorDialog = true"
          depressed
          class="mr-2"
          color="primary"
          >新增查核因子</v-btn
        >
        <v-btn
          v-if="versionData.status == 0 && rolesCheck('unitFieldByIdExport')"
          @click="importDialogOpen"
          depressed
          class="mr-2"
          color="primary"
          >匯入</v-btn
        >
        <v-btn
          v-if="
            versionData.status == 1 &&
            ((versionData.group == '分公司' &&
              rolesCheck('unitFieldByIdGroupStatus')) ||
              (versionData.group != '分公司' &&
                rolesCheck('unitFieldByIdStatus')))
          "
          @click="changeStatus(0)"
          depressed
          class="mr-2 noFocus"
          color="primary"
          outlined
          >修改</v-btn
        >
      </v-col>
    </v-row>
    <div class="d-inline-flex">
      <v-select
        v-model="riskType"
        outlined
        class="inputWhiteBg mr-4 mb-4"
        style="max-width: 220px"
        hide-details
        dense
        label="風險類型"
        :items="riskTypeList"
        clearable
        :menu-props="{ offsetY: true }"
        @change="changeRiskType"
      ></v-select>
      <v-select
        v-model="riskTypeItem"
        outlined
        class="inputWhiteBg mb-4"
        style="max-width: 220px"
        hide-details
        dense
        clearable
        label="風險項目"
        :items="riskTypeItems"
        :menu-props="{ offsetY: true }"
        :disabled="!riskType"
        @change="getUnitFieldById"
      ></v-select>
    </div>
    <v-data-table
      :headers="headers"
      :items="unitFieldList"
      hide-default-footer
      class="material-table"
    >
      <template #[`header.actions`]>
        <span v-if="versionData.status == 0 && rolesCheck('unitFieldByIdEdit')"
          >操作</span
        >
      </template>
      <template #[`item.factor`]="{ item }">
        <span v-if="!item.edit">{{ item.factor }}</span>
        <div v-if="item.edit" class="d-flex align-end mb-4">
          <v-textarea
            v-model="item.factor"
            class="mt-3 tableTextarea"
            rows="1"
            auto-grow
            dense
          ></v-textarea>
        </div>
      </template>
      <template #[`item.manType`]="{ item }">
        <span v-if="!item.edit">{{ item.manType || "-" }}</span>
        <material-select
          v-else
          v-model="item.manType"
          class="pt-3 tableSelect"
          label="選擇類型"
          :items="manTypeList"
        >
        </material-select>
      </template>
      <template #[`item.report`]="{ item }">
        <span v-if="!item.edit">{{ item.report || "-" }}</span>
        <div v-if="item.edit" class="d-flex align-end mb-4">
          <v-text-field
            dense
            v-model="item.report"
            class="mt-3 tableRefItem"
            style="width: 80px"
          ></v-text-field>
        </div>
      </template>
      <template #[`item.refItem`]="{ item }">
        <span v-if="!item.edit">{{ item.refItem || "-" }}</span>
        <div v-if="item.edit" class="d-flex align-end mb-4">
          <v-text-field
            dense
            v-model="item.refItem"
            class="mt-3 tableRefItem"
            style="width: 80px"
          ></v-text-field>
        </div>
      </template>
      <template #[`item.enabled`]="{ item }">
        <v-chip
          @click="openFatorSuspendConfrim(item)"
          v-if="item.enabled == true"
          color="primary"
          small
          >啟用</v-chip
        >
        <v-chip @click="taggleFactorStatus(item, true)" v-else small
          >停用</v-chip
        >
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex" v-if="versionData.status == 0">
          <v-icon
            v-if="item.edit == true"
            @click="
              saveItem(item);
              item.edit = false;
            "
            class="mr-2"
            >mdi-content-save</v-icon
          >
          <v-icon v-if="item.edit == true" @click="item.edit = false"
            >mdi-close-circle</v-icon
          >
          <v-icon v-else @click="item.edit = true" :disabled="isFromSignOff()"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
    </v-data-table>
    <material-pagination
      :totalPages="totalPages"
      @pageFilter="pageFilter"
    ></material-pagination>
    <!-- ====== Dialog ====== -->
    <!-- 儲存成功 -->
    <material-dialog
      v-model="saveSuccessDialog"
      title="儲存成功"
      max-width="400"
    >
      單位版本
      <chip-grey
        >{{ versionData.year }}-{{ versionText(versionData.no) }}</chip-grey
      >
      已成功儲存
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveSuccessDialog = false"
          >我知道了</v-btn
        >
      </template>
    </material-dialog>
    <!-- 新增查核因子 -->
    <material-dialog
      v-model="addFactorDialog"
      title="新增查核因子"
      max-width="380"
    >
      <material-select
        v-model="addData.type"
        ref="addFactorSelect"
        prependTitle="風險類型"
        :items="riskTypeList"
        @change="riskTypeItems = riskTypeMatchItem[addData.type]"
      ></material-select>
      <material-select
        v-model="addData.item"
        prependTitle="風險項目"
        :items="riskTypeItems"
        :disabled="riskTypeItems == null || riskTypeItems.length == 0"
      >
      </material-select>
      <v-textarea v-model="addData.factor" outlined class="textarea">
        <template #prepend>
          <div class="text-no-wrap mr-2">查核因子</div>
        </template>
      </v-textarea>
      <material-select
        v-model="addData.manType"
        prependTitle="人力類型"
        :items="manTypeList"
      ></material-select>
      <v-text-field
        v-model="addData.report"
        dense
        outlined
        class="material-input mb-3"
        single-line
        hide-details
      >
        <template #prepend>
          <div class="text-no-wrap" style="margin-right: -7px">對應報告書</div>
        </template>
      </v-text-field>
      <!-- <material-input
        v-model="addData.report"
        prependTitle="對應報告書"
        dense
      ></material-input> -->
      <material-input
        v-model="addData.refItem"
        prependTitle="對應查項"
        dense
      ></material-input>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelAdd" depressed outlined color="grey">取消</v-btn>
        <v-btn @click="addFactor" depressed color="primary">建立</v-btn>
      </template>
    </material-dialog>
    <!-- 匯入資料 -->
    <dialog-import
      @close="importDialog.open = false"
      @reset="importDialog.errors = []"
      @submit="importSubmit"
      v-bind="importDialog"
    ></dialog-import>

    <!-- 停用查核因子 -->
    <material-dialog
      v-model="fatorSuspendConfrim"
      title="停用查核因子"
      max-width="420"
    >
      您確定要停用查核因子 「<span class="primary--text">{{
        nowFactor.factor
      }}</span
      >」 嗎？
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="taggleFactorStatus(nowFactor, false)"
          depressed
          >停用因子</v-btn
        >
        <btn-cancel @click="fatorSuspendConfrim = false">保留因子</btn-cancel>
      </template>
    </material-dialog>
  </v-container>
</template>
<script>
import { sync } from "vuex-pathify";

import AlertCard from "./components/AlertCard.vue";
import {
  apiGetUnitFieldById,
  apiPatchUnitFieldByIdStatus,
  apiAddUnitFieldByIdFactor,
  apiPatchUnitFieldByIdFactor,
} from "../../api/unit-field";
import { fromSignOffMixin, checkRolesMixin, versionMixin } from "@/helper";
import { apiGetRiskTypeById } from "../../api/risk-type";

export default {
  components: { AlertCard },
  mixins: [fromSignOffMixin, checkRolesMixin, versionMixin],
  data() {
    return {
      // --temp--
      unitFieldId: this.$route.params.id,
      versionData: {
        year: "2022",
        No: "01",
        unit: "車險商品部",
        status: 0,
      },
      // ----------------
      editMode: false,
      displayContent: [],
      saveSuccessDialog: false,
      addFactorDialog: false,
      importDialog: {
        // open: false,
        // type: "unitField",
        // errors: [],
      },
      importErrorDialog: false,
      fatorSuspendConfrim: false,
      headers: [
        {
          text: "風險類型",
          align: "start",
          sortable: false,
          value: "type",
          width: 120,
        },
        {
          text: "風險項目",
          align: "start",
          sortable: false,
          value: "item",
          width: 130,
        },
        {
          text: "查核因子",
          align: "start",
          sortable: false,
          value: "factor",
        },
        {
          text: "人力類型",
          align: "start",
          sortable: false,
          width: 150,
          value: "manType",
        },
        {
          text: "對應報告書",
          align: "start",
          sortable: false,
          value: "report",
        },
        {
          text: "對應查項",
          align: "start",
          sortable: false,
          value: "refItem",
        },
        {
          text: "狀態",
          align: "center",
          sortable: false,
          value: "enabled",
        },
        {
          text: "操作",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      unitFieldList: [
        // {
        //   id: 1,
        //   type: "06.保險風險",
        //   item: "06.01.核保作業",
        //   factor:
        //     "要/被保險人投保須知，未依「金融服務業提供金融商品或服務前說明契約重要內容及揭露風險辦法」之規定",
        //   manType: "",
        //   report: "XFile",
        //   refItem: "Q00123",
        //   status: 0,
        //   edit: false,
        // },
        // {
        //   id: 2,
        //   type: "06.保險風險",
        //   item: "06.01.核保作業",
        //   factor:
        //     "要/被保險人投保須知，未依「金融服務業提供金融商品或服務前說明契約重要內容及揭露風險辦法」之規定",
        //   manType: "",
        //   report: "XFile",
        //   refItem: "Q00123",
        //   status: 0,
        //   edit: false,
        // },
        // {
        //   id: 3,
        //   type: "06.保險風險",
        //   item: "06.01.核保作業",
        //   factor:
        //     "要/被保險人投保須知，未依「金融服務業提供金融商品或服務前說明契約重要內容及揭露風險辦法」之規定",
        //   manType: "",
        //   report: "XFile",
        //   refItem: "",
        //   status: 0,
        //   edit: false,
        // },
      ],
      nowFactor: {},
      importError: null,
      // 風險類型, 風險項目搜尋列表
      riskTypeId: this.$route.query.riskTypeId,
      riskTypeList: [],
      riskTypeItems: [],
      riskTypeMatchItem: {},
      riskType: null,
      riskTypeItem: null,
      // 分頁參數
      totalPages: 1,
      page: 1,
      itemsPerPage: 10,
      // 新增參數
      addData: {
        type: null,
        item: null,
        factor: null,
        manType: null,
        report: null,
        refItem: null,
      },
      manTypeList: [
        "企業核保",
        "企業理賠",
        "個人核保",
        "個人理賠",
        "財務會計",
        "共同領域",
        "資訊",
      ],
    };
  },
  computed: {
    ...sync("global", ["alert"]),
  },
  async mounted() {
    // this.setTextareaHeight();
    console.log(this.versionData);
    await this.getUnitFieldById();
    await this.getRiskType();
  },
  methods: {
    async changeStatus(status) {
      try {
        let statusData = {
          status: status,
        };
        let res = await apiPatchUnitFieldByIdStatus(
          this.unitFieldId,
          statusData
        );
        this.getUnitFieldById();
      } catch (error) {
        console.log(error);
      }
      this.versionData.status = status;
    },
    taggleFactorStatus(item, to) {
      if (!item.edit) return;
      item.enabled = to;
      if (to == 0) {
        this.fatorSuspendConfrim = false;
      }
    },
    // 取得單位領域設定列表
    async getUnitFieldById() {
      try {
        let searchData = this.makeUrlData();
        let res = await apiGetUnitFieldById(this.unitFieldId, searchData);
        this.unitFieldList = res.data.content;
        if (res.data.riskTypeId == undefined) {
          this.riskTypeId = 1;
        } else {
          this.riskTypeId = res.data.riskTypeId;
        }
        this.versionData = res.data.unitField;
        this.setEditParams();
        console.log(this.riskTypeMatchItem);
      } catch (error) {
        console.log(error);
      }
    },
    // Set edit params in tableData
    setEditParams() {
      for (let index in this.unitFieldList) {
        this.$set(this.unitFieldList[index], "edit", false);
      }
      console.log(this.unitFieldList);
    },
    // 取得風險類型與風險項目列表
    async getRiskType() {
      try {
        let res = await apiGetRiskTypeById(this.riskTypeId);
        for (let item of res.data.content) {
          this.riskTypeList.push(item.type);
          this.riskTypeMatchItem[item.type] = [];
          for (let item2 of item.items) {
            console.log(item2);
            this.riskTypeMatchItem[item.type].push(item2.name);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    openFatorSuspendConfrim(item) {
      if (!item.edit) return;
      this.nowFactor = item;
      this.fatorSuspendConfrim = true;
    },
    // === Import Dialog ===
    importDialogOpen() {
      this.importDialog = {
        open: true,
        type: "unitField",
      };
    },
    importSubmit() {
      console.log("submit");
      // TODO: Deal with submit
      // ------------
      //if Error
      this.importDialog = {
        ...this.importDialog,
        errors: ["格式錯誤", "缺少XX欄位"],
      };
    },
    // 分頁搜尋
    pageFilter() {
      let pageParams = arguments[0];
      console.log(arguments[0]);
      this.page = pageParams.page;
      this.itemsPerPage = pageParams.itemsPerPage;
      this.getUnitFieldById();
    },
    // 建立過濾網址參數
    makeUrlData() {
      let searchData = {};
      searchData["page"] = this.page;
      searchData["size"] = this.itemsPerPage;
      if (![undefined, null, ""].includes(this.riskType)) {
        searchData["type"] = this.riskType;
      }
      if (![undefined, null, ""].includes(this.riskTypeItem)) {
        searchData["item"] = this.riskTypeItem;
      }
      return searchData;
    },
    changeRiskType() {
      console.log("change risk type");
      if (this.riskType == null) {
        this.riskTypeItem = null;
      }
      this.riskTypeItems = this.riskTypeMatchItem[this.riskType];
      this.getUnitFieldById();
    },
    rowsEdit(item, index) {},
    // 儲存內容
    async saveItem(item) {
      try {
        let postData = {
          factor: item.factor,
          manType: item.manType,
          refItem: item.refItem,
          enabled: item.enabled,
        };
        let res = await apiPatchUnitFieldByIdFactor(
          this.unitFieldId,
          item.id,
          postData
        );
      } catch (error) {}
    },
    // 取消新增查核因子
    cancelAdd() {
      this.addFactorDialog = false;
      this.resetAddData();
    },
    // 建立查核因子
    async addFactor() {
      try {
        let checkFactorNull = this.checkFactorColumnNull();
        if (checkFactorNull == true) {
          this.alert = {
            open: true,
            content: "全部欄位為必填，尚有欄位未填",
          };
          return;
        }
        let res = await apiAddUnitFieldByIdFactor(
          this.unitFieldId,
          this.addData
        );
        this.resetAddData();
        this.addFactorDialog = false;
        this.getUnitFieldById();
        console.log(res);
      } catch (error) {
        console.log(error);
      }
      this.addFactorDialog = false;
    },
    // 重置新增查核因子參數
    resetAddData() {
      this.addData = {
        type: null,
        item: null,
        factor: null,
        manType: null,
        report: null,
        refItem: null,
      };
    },
    closeEdit(item, index) {
      console.log(this.unitFieldList[index].edit);
      this.$set(this.unitFieldList[index], "edit", false);
    },
    // 檢查查核因子欄位
    checkFactorColumnNull() {
      let checkNull = false;
      for (let key in this.addData) {
        if (["", null, undefined].includes(this.addData[key])) {
          checkNull = true;
        } else if (!this.addData[key].trim()) {
          checkNull = true;
        }
      }
      console.log(checkNull);
      return checkNull;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/main.scss";
.titleWidth {
  max-width: 550px;
  min-width: 500px;
}
.depSelect {
  max-width: 300px;
}
.textarea {
  width: 60%;
  &:deep(.v-input__control) {
    min-width: 200px;
    background: #fff;
  }
}
.tableTextarea,
.tableRefItem {
  font-size: 14px;
}
.importArea {
  @include flexCenter;
  @include boxSize(100%, 120px);
  border: 2px dashed #cacaca;
  transition: all 0.2s ease;
  &:hover {
    background-color: rgba(#000, 0.05);
  }
}
.tableSelect {
  &:deep(.v-input__control) {
    min-width: 130px;
    background: #fff;
  }
}
.material-input {
  width: 60%;
  &:deep(.v-input__control) {
    min-width: 200px;
  }
  &:deep(.v-input__prepend-outer) {
    color: #222;
    div {
      min-width: 65px;
    }
  }
  &.noBorder {
    &:deep(fieldset) {
      border-color: #fff !important;
    }
  }
}
</style>
