import req from "./api"
export const apiGetRiskType = (data)=>req("get","/risk-type",data)
export const apiGetVerions = ()=>req("get","/risk-type/versions")
export const apiGetNextVersion = ()=>req("get","/risk-type/next-version")
export const apiDeleteRiskType = (id)=>req("delete","/risk-type/"+id)
export const apiCancelRiskType = (id)=>req("post","/risk-type/"+id+"/cancel")
export const apiRecoverRiskType = (id)=>req("post","/risk-type/"+id+"/recover")
export const apiAddRiskType = (data)=>req("post","/risk-type", data)
export const apiGetHistory = (id)=>req("get","/risk-type/"+id+'/history')
export const apiGetRiskTypeById = (id)=>req("get","/risk-type/"+id)
export const apiSaveRiskTypeById = (id, data)=>req("post","/risk-type/"+id, data)
export const apiSubmitRiskTypeById = (id, data)=>req("post","/risk-type/"+id+"/submit", data)
export const apiExportRiskType = (data)=>req("post","/risk-type/export", data)
